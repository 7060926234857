export enum LaunchStatus {
  Live = 'LIVE',
  OpenBeta = 'OPEN_BETA',
  ClosedBeta = 'CLOSED_BETA',
}

export type Config = {
  // SEARCH PARAMS ENCRYPTION KEYS
  SEARCH_PARAM_ENCRYPTION_KEY: string;
  SEARCH_PARAM_HMAC_KEY: string;
  // Onboarding_version
  ONBOARDING_VERSION: string;
  // Metadata
  OTP_LENGTH: number;
  PASSCODE_LENGTH: number;
  LAUNCH_STATUS: LaunchStatus;
  // API
  BASE_URL: string;
  API_BASE_URL: string;
  WALLET_API_BASE_URL: string;
  BENEFICIARY_API_BASE_URL: string;
  INTERBANK_TRANSFER_API_BASE_URL: string;
  XCALLY_API_BASE_URL: string;
  // User
  SESSION_EXPIRY: string;
  // Providers
  VERIFICATION_PROVIDER: string;
  // Google Pay
  GOOGLE_PAY_API_VERSION: number;
  GOOGLE_PAY_API_VERSION_MINOR: number;
  GOOGLE_PAY_CARD_NETWORKS: string[];
  GOOGLE_PAY_CARD_AUTH_METHODS: string[];
  GOOGLE_PAY_GATEWAY: string;
  GOOGLE_PAY_GATEWAY_MERCHANT_ID: string;
  GOOGLE_PAY_MERCHANT_NAME: string;
  GOOGLE_PAY_ENVIRONMENT: string;
  // Apple Pay
  APPLE_PAY_VERSION: number;
  APPLE_PAY_MERCHANT_ID: string;
  APPLE_PAY_SUPPORTED_NETWORKS: string[];
  APPLE_PAY_MERCHANT_CAPABILITIES: string[];
  // Transaction
  CONVERSION_RATE_REFETCH_INTERVAL: number;
  MOCK_TRANSACTION_RECIPIENTS: boolean;
  MOCK_TRANSACTION_INTERNAL_ACCOUNT_QUOTE: boolean;
  // Support
  SUPPORT_AVAILABILITY: string;
  SUPPORT_EMAIL: string;
  SUPPORT_PHONE: string;
  ZENDESK_KEY: string;
  XCALLY_API_KEY: string;
  XCALLY_CLIENT_NAME: string;
  // Wallet
  MOCK_WALLETS: boolean;
  // Google OAuth
  GOOGLE_OAUTH_SCOPE: string;
  GOOGLE_OAUTH_CLIENT_ID: string;
  GOOGLE_OAUTH_RESPONSE_TYPE: string;
  GOOGLE_OAUTH_RESPONSE_MODE: string;
  // Apple OAuth
  APPLE_OAUTH_SCOPE: string;
  APPLE_OAUTH_CLIENT_ID: string;
  APPLE_OAUTH_RESPONSE_TYPE: string;
  APPLE_OAUTH_RESPONSE_MODE: string;
  // Analytics
  MIXPANEL_TOKEN: string;
  CUSTOMER_IO_WRITE_KEY: string;
  SINGULAR_SDK_KEY: string;
  SINGULAR_SDK_SECRET: string;
  SINGULAR_SDK_PRODUCT_ID: string;
  // Feature Flag Service
  FEATURE_FLAG_SERVICE_PROVIDER: string;
  // Feature Flags
  ALLOW_PAYMENT_TO_OTHER_ACCOUNT: boolean;
  ENABLE_PAYMENT: boolean;
  UNLEASH_FRONTEND_API_URL: string;
  UNLEASH_FRONTEND_API_TOKEN: string;
  // New Relic
  NR_ACCOUNT_ID: string;
  NR_APP_ID: string;
  NR_LICENSE_KEY: string;
  ENABLE_NR: boolean;
};

export const config: Config = {
  // SEARCH PARAMS ENCRYPTION KEYS
  SEARCH_PARAM_ENCRYPTION_KEY: getConfig(
    'NEXT_PUBLIC_SEARCH_PARAM_ENCRYPTION_KEY',
    '123456',
  ),
  SEARCH_PARAM_HMAC_KEY: getConfig(
    'NEXT_PUBLIC_SEARCH_PARAM_HMAC_KEY',
    '123456',
  ),
  // Onboarding_version
  ONBOARDING_VERSION: getConfig('NEXT_PUBLIC_ONBOARDING_VERSION', 'v1'),
  // Analytics
  CUSTOMER_IO_WRITE_KEY: getConfig(
    'NEXT_PUBLIC_CUSTOMER_IO_WRITE_KEY',
    'eb3bdc97b7e8a3e28d9e',
  ),
  MIXPANEL_TOKEN: getConfig(
    'NEXT_PUBLIC_MIXPANEL_TOKEN',
    'a7fe307e4079069f5711e9ade005ec6b',
  ),
  SINGULAR_SDK_KEY: getConfig(
    'NEXT_PUBLIC_SINGULAR_SDK_KEY',
    'moniepoint_inc_c670e545',
  ),
  SINGULAR_SDK_SECRET: getConfig(
    'NEXT_PUBLIC_SINGULAR_SDK_SECRET',
    '766bc80115e4db1298bde6b66faf14a3',
  ),
  SINGULAR_SDK_PRODUCT_ID: getConfig(
    'NEXT_PUBLIC_SINGULAR_SDK_PRODUCT_ID',
    'com.moniepoint.fx-web.dev',
  ),
  // Metadata
  OTP_LENGTH: Number(getConfig('NEXT_PUBLIC_OTP_LENGTH', '6')),
  LAUNCH_STATUS: getConfig(
    'NEXT_PUBLIC_LAUNCH_STATUS',
    LaunchStatus.ClosedBeta,
  ) as LaunchStatus,
  PASSCODE_LENGTH: Number(getConfig('NEXT_PUBLIC_PASSCODE_LENGTH', '6')),
  // API
  BASE_URL: getConfig(
    'NEXT_PUBLIC_BASE_URL',
    'https://fx-web-app.development.moniepoint.com',
  ),
  API_BASE_URL: getConfig(
    'NEXT_PUBLIC_API_BASE_URL',
    'https://fx-api-gateway.development.moniepoint.com/gateway',
  ),
  WALLET_API_BASE_URL: getConfig(
    'NEXT_PUBLIC_WALLET_API_BASE_URL',
    'https://fx-account-management.development.moniepoint.com/api/v1',
  ),
  BENEFICIARY_API_BASE_URL: getConfig(
    'NEXT_PUBLIC_BENEFICIARY_API_BASE_URL',
    'https://fx-beneficiary-service.development.moniepoint.com/api/v1',
  ),
  INTERBANK_TRANSFER_API_BASE_URL: getConfig(
    'NEXT_PUBLIC_INTERBANK_TRANSFER_API_BASE_URL',
    'https://fx-interbank-transfer-service.development.moniepoint.com/api/v1',
  ),
  XCALLY_API_BASE_URL: getConfig(
    'NEXT_PUBLIC_XCALLY_API_BASE_URL',
    'https://moniepoint-crm-api-channel.staging.teamapt.com/api/v1',
  ),
  // User
  SESSION_EXPIRY: getConfig('NEXT_PUBLIC_SESSION_EXPIRY', '36000'),
  // Providers
  VERIFICATION_PROVIDER: 'sumsub',
  // Google Pay
  GOOGLE_PAY_API_VERSION: Number(
    getConfig('NEXT_PUBLIC_GOOGLE_PAY_API_VERSION', '2'),
  ),
  GOOGLE_PAY_API_VERSION_MINOR: Number(
    getConfig('NEXT_PUBLIC_GOOGLE_PAY_API_VERSION_MINOR', '0'),
  ),
  GOOGLE_PAY_CARD_NETWORKS: getJsonConfig(
    'NEXT_PUBLIC_GOOGLE_PAY_CARD_NETWORKS',
    ['AMEX', 'DISCOVER', 'MASTERCARD', 'VISA'],
  ),
  GOOGLE_PAY_CARD_AUTH_METHODS: getJsonConfig(
    'NEXT_PUBLIC_GOOGLE_PAY_CARD_AUTH_METHODS',
    ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
  ),
  GOOGLE_PAY_GATEWAY: getConfig('NEXT_PUBLIC_GOOGLE_PAY_GATEWAY'),
  GOOGLE_PAY_GATEWAY_MERCHANT_ID: getConfig(
    'NEXT_PUBLIC_GOOGLE_PAY_GATEWAY_MERCHANT_ID',
    'pk_sbox_xe4gjku22xnzqxxinojiduf3em2',
  ),
  GOOGLE_PAY_MERCHANT_NAME: getConfig(
    'NEXT_PUBLIC_GOOGLE_PAY_MERCHANT_NAME',
    'Checkout.com',
  ),
  GOOGLE_PAY_ENVIRONMENT: getConfig(
    'NEXT_PUBLIC_GOOGLE_PAY_ENVIRONMENT',
    'TEST',
  ),
  // Apple Pay
  APPLE_PAY_VERSION: Number(getConfig('NEXT_PUBLIC_APPLE_PAY_VERSION', '6')),
  APPLE_PAY_MERCHANT_ID: getConfig(
    'NEXT_PUBLIC_APPLE_PAY_MERCHANT_ID',
    'merchant.com.moniepoint.mobile.sandbox',
  ),
  APPLE_PAY_MERCHANT_CAPABILITIES: getJsonConfig(
    'NEXT_PUBLIC_APPLE_PAY_MERCHANT_CAPABILITIES',
    ['supports3DS'],
  ),
  APPLE_PAY_SUPPORTED_NETWORKS: getJsonConfig(
    'NEXT_PUBLIC_APPLE_PAY_SUPPORTED_NETWORKS',
    ['visa', 'masterCard', 'amex', 'discover'],
  ),

  // Transaction
  CONVERSION_RATE_REFETCH_INTERVAL: Number(
    getConfig(
      'NEXT_PUBLIC_CONVERSION_RATE_REFETCH_INTERVAL',
      '600000', // 10 minutes
    ),
  ),
  MOCK_TRANSACTION_RECIPIENTS:
    getConfig('NEXT_PUBLIC_MOCK_TRANSACTION_RECIPIENTS', 'false') === 'true',
  MOCK_TRANSACTION_INTERNAL_ACCOUNT_QUOTE:
    getConfig(
      'NEXT_PUBLIC_MOCK_TRANSACTION_INTERNAL_ACCOUNT_QUOTE',
      'false',
    ) === 'true',
  // Support
  SUPPORT_AVAILABILITY: getConfig(
    'NEXT_PUBLIC_SUPPORT_AVAILABILITY',
    'Mon to Fri 8am to 5pm GMT+1',
  ),
  SUPPORT_EMAIL: getConfig(
    'NEXT_PUBLIC_SUPPORT_EMAIL',
    'helpuk@moniepoint.com',
  ),
  SUPPORT_PHONE: getConfig('NEXT_PUBLIC_SUPPORT_PHONE', '+44 330 024 5551'),
  ZENDESK_KEY: getConfig('NEXT_PUBLIC_ZENDESK_KEY', ''),
  XCALLY_API_KEY: getConfig('XCALLY_API_KEY', 'aLU7bx3Xs5dr9w5PsNji'),
  XCALLY_CLIENT_NAME: getConfig('XCALLY_CLIENT_NAME', 'MoniepointFX'),
  // Wallet
  MOCK_WALLETS: getConfig('NEXT_PUBLIC_MOCK_WALLETS', 'false') === 'true',
  // Google OAuth
  GOOGLE_OAUTH_SCOPE: getConfig(
    'NEXT_PUBLIC_GOOGLE_OAUTH_SCOPE',
    'openid email profile',
  ),
  GOOGLE_OAUTH_CLIENT_ID: getConfig(
    'NEXT_PUBLIC_GOOGLE_OAUTH_CLIENT_ID',
    '1234567890-abcdefghijklmnopqrstuvwxyz.apps.googleusercontent.com',
  ),
  GOOGLE_OAUTH_RESPONSE_TYPE: getConfig(
    'NEXT_PUBLIC_GOOGLE_OAUTH_RESPONSE_TYPE',
    'id_token',
  ),
  GOOGLE_OAUTH_RESPONSE_MODE: getConfig(
    'NEXT_PUBLIC_GOOGLE_OAUTH_RESPONSE_MODE',
    'query',
  ),
  // Apple OAuth
  APPLE_OAUTH_SCOPE: getConfig('NEXT_PUBLIC_APPLE_OAUTH_SCOPE', 'name email'),
  APPLE_OAUTH_CLIENT_ID: getConfig(
    'NEXT_PUBLIC_APPLE_OAUTH_CLIENT_ID',
    'com.moniepoint.example',
  ),
  APPLE_OAUTH_RESPONSE_TYPE: getConfig(
    'NEXT_PUBLIC_APPLE_OAUTH_RESPONSE_TYPE',
    'code',
  ),
  APPLE_OAUTH_RESPONSE_MODE: getConfig(
    'NEXT_PUBLIC_APPLE_OAUTH_RESPONSE_MODE',
    'form_post',
  ),
  // Feature Flag Service
  FEATURE_FLAG_SERVICE_PROVIDER: getConfig(
    'NEXT_PUBLIC_FEATURE_FLAG_SERVICE_PROVIDER',
    'unleash',
  ),
  UNLEASH_FRONTEND_API_URL: getConfig(
    'NEXT_PUBLIC_UNLEASH_FRONTEND_API_URL',
    'https://feature-flag.development.moniepoint.com/api/frontend',
  ),
  UNLEASH_FRONTEND_API_TOKEN: getConfig(
    'NEXT_PUBLIC_UNLEASH_FRONTEND_API_TOKEN',
    'default:development.unleash-insecure-frontend-api-token',
  ),
  // Feature Flags
  ALLOW_PAYMENT_TO_OTHER_ACCOUNT:
    getConfig('NEXT_PUBLIC_ALLOW_PAYMENT_TO_OTHER_ACCOUNT', 'true') === 'true',
  ENABLE_PAYMENT: getConfig('NEXT_PUBLIC_ENABLE_PAYMENT', 'true') === 'true',
  NR_ACCOUNT_ID: getConfig('NR_ACCOUNT_ID', '1968949'),
  NR_APP_ID: getConfig('NR_APP_ID', '594503327'),
  NR_LICENSE_KEY: getConfig('NR_LICENSE_KEY', 'NRBR-f1ff8e1e07f09ef729b'),
  ENABLE_NR: getConfig('ENABLE_NR', 'true') === 'true',
};

declare global {
  interface Window {
    inject: {
      CONFIG: Config;
    };
  }
}

function getConfig(key: string, defaultValue = ''): string {
  if (typeof window !== 'undefined' && window.inject?.CONFIG) {
    const strippedKey = key.replace(/^NEXT_PUBLIC_/, '');
    const configValue = window.inject.CONFIG[
      strippedKey as keyof Config
    ] as string;

    if (configValue !== undefined) {
      return configValue;
    }
  }

  const envValue = process.env[key];

  return envValue ?? defaultValue;
}

function getJsonConfig<T>(key: string, defaultValue?: T): T {
  const value = getConfig(key);

  try {
    return JSON.parse(value);
  } catch (error) {
    return defaultValue as T;
  }
}
