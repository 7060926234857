interface Zendesk {
  (command: 'messenger', action: string, callback?: (arg?: any) => void): void;
  (command: 'messenger:on', action: string, callback: () => void): void;
}

declare global {
  interface Window {
    zE?: Zendesk;
  }
}

export function logoutFromZenDesk(): void {
  if (window.zE) {
    window.zE('messenger', 'logoutUser');
  }
}

export function loginZendesk(token: string): void {
  if (token && window.zE) {
    window.zE('messenger', 'loginUser', function (callback) {
      if (callback) callback(token);
    });
  }
}

export function closeZendeskWidget(): void {
  if (window.zE) {
    window.zE('messenger', 'close');
  }
}

export function openWidget(token?: string): void {
  if (window.zE) {
    window.zE('messenger', 'open');
  }
}

export function hideZendeskWidget(): void {
  if (window.zE) {
    window.zE('messenger', 'hide');
  }
}
export function showZendeskWidget(): void {
  if (window.zE) {
    window.zE('messenger', 'show');
  }
}
