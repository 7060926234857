'use client';

import { useEffect } from 'react';

import {
  hideZendeskWidget,
  showZendeskWidget,
} from '@/features/support/utils/zendesk';

export function ZendeskConfigScript() {
  useEffect(() => {
    // Create the script element
    if (!document.getElementById('ze-snippet')) {
      const script = document.createElement('script');
      script.id = 'ze-snippet';
      script.src =
        'https://static.zdassets.com/ekr/snippet.js?key=0c0bc6b0-1014-4f9c-84b3-c0f989fb4aba';
      //   src={`https://static.zdassets.com/ekr/snippet.js?key=${config.ZENDESK_KEY}`}
      script.async = true;

      document.head.appendChild(script);
    }

    showZendeskWidget();

    return () => {
      hideZendeskWidget();
    };
  }, []);

  return null;
}
